import logo from "./logo.svg";
import "./App.css";
import main from "./assets/flying.png";
import sideways from "./assets/Sideways_Fly.png";
import comic from "./assets/comic.jpg";
import comic2 from "./assets/comic2.jpg";
import lambo from "./assets/Holding_Lambo.png";
import video from "./assets/video.mp4";

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-red-500 to-sky-500 w-screen">
      <div className="flex flex-row w-screen space-x-10 items-center justify-center py-7 text-sm lg:text-2xl font-bold text-neutral-200 z-50">
        <a className="z-50" href="https://twitter.com/SolpermanSOL">
          Twitter
        </a>
        <a className="z-50" href="https://t.me/SolpermanPortal">
          Telegram
        </a>
        <a
          className="z-50"
          href="https://dexscreener.com/solana/37J7yWJa9rGYUXjmx4UFJ4awVUzuYLUZ1PQCHZKvgCS2"
        >
          Dexscreener
        </a>
        <a
          className="z-50"
          href="https://www.dextools.io/app/en/solana/pair-explorer/Beuo49exhbnK9t31p8zWtUow7qkcordExnggAG85XmGj?t=1711443509255"
        >
          DEXTools
        </a>
        <a
          className="z-50"
          href="https://drive.google.com/file/d/1zIxqccNYTb3KddcrtaPearCmBbarQda-/view?usp=sharing"
        >
          Whitepaper
        </a>
      </div>

      <div className="flex flex-col w-screen items-center justify-center py-32 z-50">
        <div className="text-5xl lg:text-9xl font-bold py-8 z-50 text-white">
          SOLPERMAN
        </div>
        <div className="text-3xl font-semibold z-50 text-white text-center px-10">
          "On my world it stands for Pump"
        </div>
      </div>

      <div className="flex flex-col w-screen items-center space-y-10">
        <div className="text-xl lg:text-3xl font-bold pt-10 text-white text-center text-wrap w-screen break-words px-10">
          CA: 37J7yWJa9rGYUXjmx4UFJ4awVUzuYLUZ1PQCHZKvgCS2
        </div>
        <img className="w-5/6 lg:w-1/2 pt-48" src={comic} />
        <img className="w-5/6 lg:w-1/2" src={comic2} />
        <img className="w-5/6 pb-48" src={lambo} />
      </div>
      <img className="absolute top-0" src={sideways} />
    </div>
  );
}

export default App;
